var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "工商信息", fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c("div", { staticClass: "gongshang-content" }, [
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _vm._v(_vm._s(_vm.info.companyName)),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("所在地")]),
          _c("span", [_vm._v(_vm._s(_vm.info.areaName))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("统一社会信用代码")]),
          _c("span", [_vm._v(_vm._s(_vm.info.creditCode))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("注册号")]),
          _c("span", [_vm._v(_vm._s(_vm.info.regNumber))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("营业状态")]),
          _c("span", [_vm._v(_vm._s(_vm.info.businessStatus))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("法定代表人")]),
          _c("span", [_vm._v(_vm._s(_vm.info.faRen))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("企业地址")]),
          _c("span", { staticClass: "etc" }, [
            _vm._v(_vm._s(_vm.info.address)),
          ]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("经营项目")]),
          _c("span", { staticClass: "etc" }, [
            _vm._v(_vm._s(_vm.info.bussinessDes)),
          ]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("注册资金")]),
          _c("span", [_vm._v(_vm._s(_vm.info.regM))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("注册时间")]),
          _c("span", [_vm._v(_vm._s(_vm.info.issueTime))]),
        ]),
        _c("p", { staticClass: "gongshang-ex border-bottom" }, [
          _c("span", [_vm._v("登记机关")]),
          _c("span", [_vm._v(_vm._s(_vm.info.regOrgName))]),
        ]),
      ]),
      _c("div", { staticClass: "bottom-btn" }, [
        _c("span", { staticClass: "cancel-btn", on: { click: _vm.back } }, [
          _vm._v("返回"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }