<template>
  <fb-page>
    <fb-header slot="header"
               title="工商信息"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <div class="gongshang-content">
      <p class="gongshang-ex border-bottom">{{info.companyName}}</p>
      <p class="gongshang-ex border-bottom">
        <span>所在地</span>
        <span>{{info.areaName}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>统一社会信用代码</span>
        <span>{{info.creditCode}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>注册号</span>
        <span>{{info.regNumber}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>营业状态</span>
        <span>{{info.businessStatus}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>法定代表人</span>
        <span>{{info.faRen}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>企业地址</span>
        <span class="etc">{{info.address}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>经营项目</span>
        <span class="etc">{{info.bussinessDes}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>注册资金</span>
        <span>{{info.regM}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>注册时间</span>
        <span>{{info.issueTime}}</span>
      </p>
      <p class="gongshang-ex border-bottom">
        <span>登记机关</span>
        <span>{{info.regOrgName}}</span>
      </p>
    </div>
    <div class='bottom-btn'>
      <span class='cancel-btn'
            @click="back">返回</span>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
export default {
  data() {
    return {
      caseId: Number,
      info: Object
    }
  },
  created() {
    // let caseId = this.$route.params.caseId
    // console.log(caseId)
    // var name = this.$route.params.name
    var name = '阿里巴巴丝路有限公司'
    this.caseId = 15667
    this.$axios
      .get(`${caseBase}/management/getInfoByKey?companyName=${name}`)
      .then(res => {
        this.info = res.data
        for (var key in this.info) {
          if (this.info[key] === '') {
            this.info[key] = '暂无数据'
          }
        }
      })
  },
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.bottom-btn {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.bottom-btn > span {
  display: inline-block;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: normal;
  color: white;
  background-color: #4b9efb;
  line-height: 50px;
  text-align: center;
}
.cancel-btn {
  background-color: #dddddd !important;
}
.gongshang-content {
  width: 92%;
  margin: 0 auto;
}
.gongshang-ex {
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  border-color: rgba(243, 244, 247, 1);
}
.etc {
  width: 70%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
